<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Adicionar Contacto de Cliente'"
      :modal="true"
      :closable="false"
    >
      <form
        name="HelpdeskproposalsChangeStatus"
        class="p-col-12"
        @submit.prevent="addContact"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="type"
                :options="['Comercial', 'Gestor', 'Pre-venda']"
                :showClear="true"
                v-model="type"
                v-bind:class="['form-control']"
              >
              </Dropdown>
              <label for="type">Selecionar Tipo</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <InputText
                name="name"
                v-model="name"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('name') },
                  'form-control',
                ]"
              >
              </InputText>

              <label for="name">Nome</label>
            </span>
            <small v-if="errors.has('name')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="email"
                name="email"
                v-model="email"
                v-validate="!telephone ? 'required' : ''"
                v-bind:class="[
                  { 'p-invalid': errors.has('email') },
                  'form-control',
                ]"
              >
              </InputText>

              <label for="email">Email</label>
            </span>
            <small v-if="errors.has('email')" class="p-error" role="alert">
              Email é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <InputNumber
                name="telephone"
                :useGrouping="false"
                v-model="telephone"
                v-validate="!email ? 'required' : ''"
                v-bind:class="[
                  { 'p-invalid': errors.has('telephone') },
                  'form-control',
                ]"
              />

              <label for="telephone">Telefone</label>
            </span>
            <small v-if="errors.has('telephone')" class="p-error" role="alert">
              Telefone é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="addContact"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import managerService from "../../services/externalManager.service";
export default {
  name: "AddClientContact",
  props: ["showToast", "show", "clientId"],
  data() {
    return {
      name: null,
      email: null,
      type: null,
      telephone: null,
    };
  },
  created() {},
  methods: {
    reset() {
      this.name = null;
      this.email = null;
      this.type = null;
      this.telephone = null;
    },
    addContact() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let bodyParams = {
          name: this.name,
          email: this.email,
          type: this.type,
          telephone: `${this.telephone}`,
        };
        return managerService.addManager(bodyParams).then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao adicionar contacto",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("contactAdded", {
              status: "error",
              contact: null,
            });
          }
          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Contacto adicionado com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("contactAdded", {
            status: "ok",
            contact: response,
          });
        });
      });
    },
    cancel() {
      this.$emit("cancel");
      return this.reset();
    },
  },
};
</script>
