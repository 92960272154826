<template>
  <div class="p-grid p-col-12 p-lg-12" v-if="Object.keys(patInfo).length > 0">
    <ConfirmDialog />
    <Dialog
      :visible.sync="showSurveyReport"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Solução/Realtório'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <div>
        <p><b>Nome:</b> {{ patInfo.name }} | {{ patInfo.agname }}</p>
        <p><b>Vendedor:</b> {{ patInfo.seller }}</p>
        <p><b>Técnico:</b> {{ patInfo.technician }}</p>
        <p v-if="patInfo.resume"><b>Resumo:</b> {{ patInfo.resume }}</p>
        <p><b>Solução:</b></p>
        <Textarea
          :value="patInfo.solution"
          :autoResize="true"
          disabled
          style="width: 100%"
          rows="1"
        />
      </div>
      <template #footer>
        <Button
          label="Fechar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="showSurveyReport = false"
        />
      </template>
    </Dialog>
    <SurveyChangeDate
      :show="changeSurveyDate"
      :showToast="true"
      :patId="patInfo.pat"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      :patResume="patInfo.resume"
      :patDate="patInfo.survey.date"
      v-on:cancel="surveyChangeDateCancel"
      v-on:setDate="surveyChangeDateSubmit"
    />
    <SurveySetBudget
      :showToast="true"
      :changeBudgetedStatus="setSurveyBudget.show"
      :budgeted="setSurveyBudget.budgeted"
      :patType="setSurveyBudget.patType"
      :patId="setSurveyBudget.patId"
      :patName="setSurveyBudget.patName"
      v-on:cancel="changeBudgetedStatusCancel"
      v-on:setBudget="changedBudgetedStatus"
    />
    <SurveyChangeBudgetResponsible
      :showToast="true"
      :show="changeBudgetedResponsible"
      :patId="patInfo.pat"
      :currentResponsibleId="patInfo.survey.userToDoId"
      v-on:cancel="changeSurveyBudgetResponsibleCancel"
      v-on:setSurveyBudgetResponsible="changedSurveyBudgetResponsible"
    />
    <SurveySetPendingStatus
      :showToast="true"
      :show="setSurveyStatusPending"
      :patId="patInfo.pat"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      v-on:cancel="setSurveyPendingCancel"
      v-on:setSurveyPending="setSurveyPending"
    />
    <SurveyRemovePendingStatus
      :showToast="true"
      :removePendingdStatus="removeSurveyStatusPending"
      :patId="patInfo.pat"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      v-on:cancel="removePendingStatusCancel"
      v-on:removePending="removePendingStatus"
    />
    <SurveySetClosedStatus
      :showToast="true"
      :setClosedStatus="setSurveyClosed"
      :patId="patInfo.pat"
      :patType="patInfo.patType"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      v-on:cancel="setClosedCancel"
      v-on:setClosed="setClosedStatus"
    />
    <SurveySetConcluedStatus
      :showToast="true"
      :setConcluedStatus="setSurveyConclude"
      :patId="patInfo.pat"
      :patService="patInfo.patType"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      v-on:cancel="setConcluedCancel"
      v-on:setConclued="setConcluedStatus"
    />
    <SurveyAddReport
      :showToast="true"
      :show="addSurveyReport"
      :patId="patInfo.pat"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      :patReference="patInfo.reference"
      v-on:cancel="addReportCancel"
      v-on:addedReport="addedReport"
    />
    <SurveyChangeSurveyResponsible
      :showToast="true"
      :show="changeSurveyResponsible"
      :patId="patInfo.pat"
      :currentRespId="patInfo.survey.technicianUserId"
      v-on:cancel="changeSurveyResponsibleCancel"
      v-on:setSurveyResponsible="changedSurveyResponsible"
    />
    <SurveyChangeSurveySeller
      :showToast="true"
      :show="changeSurveySeller"
      :patId="patInfo.pat"
      :currentSellerId="parseInt(patInfo.survey.sellerId)"
      v-on:cancel="changeSurveySellerCancel"
      v-on:setSurveySeller="changedSurveySeller"
    />
    <SurveyMarkeIntervention
      :showToast="true"
      :show="setSurveyMarked"
      :patId="patInfo.pat"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      :patResume="patInfo.resume"
      v-on:cancel="setMarkedCancel"
      v-on:marked="setMarkedOk"
    />
    <SurveySetRealizedStatus
      :showToast="true"
      :setConcluedStatus="setRealized"
      :patId="patInfo.pat"
      :patType="patInfo.patType"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      v-on:cancel="setRealizedCancel"
      v-on:setConclued="setRealizedOk"
    />
    <SurveyAddAttempt
      :showToast="true"
      :show="addAttemptSchedule"
      :patId="patInfo.pat"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      v-on:cancel="addAttemptScheduleCancel"
      v-on:addedAttemptSchedule="addAttemptScheduleOk"
    />
    <SurveySetCancelled
      :showToast="true"
      :show="setSurveyCanceled"
      :patId="patInfo.pat"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      v-on:cancel="setCanceledCancel"
      v-on:surveyCanceled="setCanceledOk"
    />
    <SurveyRemoveCanceled
      :showToast="true"
      :show="removeSurveyCanceled"
      :patId="patInfo.pat"
      :patName="patInfo.agname + ' | ' + patInfo.name"
      v-on:cancel="removeSurveyCanceled = false"
      v-on:surveyRemoveCanceled="removeCanceledOk"
    />
    <div class="p-col-12">
      <div class="card p-shadow-6">
        <h2>
          Pat {{ $route.params.id }} | {{ patInfo.agname }} | {{ patInfo.name }}
        </h2>
      </div>
    </div>
    <div
      v-bind:class="[
        { 'p-md-6': compact == false || compact == undefined },
        { 'p-md-12': compact == true },
        'p-col-12',
      ]"
    >
      <div class="card p-shadow-6" v-if="patInfo.patType == 'Survey'">
        <Accordion>
          <AccordionTab
            class="accordion-custom"
            v-if="patId == undefined && patInfo.proposal && (loggedUser.isEsa || can('seeProposals'))"
          >
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-euro p-mr-2"></i>Proposta |
                {{ patInfo.proposal.statusName }}
              </div>
            </template>
            <div class="p-col-12">
              <Proposal :proposalId="patInfo.proposal.id" />
            </div>
          </AccordionTab>
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-wallet p-mr-2"></i>Survey |
                {{ patInfo.survey.status }}
              </div>
            </template>
            <div class="p-grid">
              <div
                class="p-col-11"
                v-if="
                  loggedUser.isEsa ||
                  loggedUser.isAdmin ||
                  loggedUser.department == 'Tecnico'
                "
              >
                <Button
                  v-if="
                    patInfo.survey.status != 'Pendente' &&
                    patInfo.survey.status != 'Cancelado'
                  "
                  v-tooltip="'Marcar survey como Pendente'"
                  label="Marcar como Pendente"
                  icon="pi pi-clock"
                  class="p-button-warning p-mr-1 p-mt-1"
                  @click="setSurveyStatusPending = true"
                />
                <Button
                  v-if="patInfo.status == '2. Pendente'"
                  v-tooltip="'Retirar survey de pendente'"
                  label="Retirar de Pendente"
                  icon="pi pi-play"
                  class="p-button-success p-mr-1 p-mt-1"
                  @click="removeSurveyStatusPending = true"
                />
                <Button
                  v-if="
                    (patInfo.survey.status == 'Por fechar' ||
                      patInfo.survey.status == 'Por concluir') &&
                    (loggedUser.isEsa || loggedUser.isAdmin)
                  "
                  v-tooltip="'Fechar Survey'"
                  label="Fechar Survey"
                  icon="pi pi-lock"
                  class="p-button-success p-mr-1 p-mt-1"
                  @click="setSurveyClosed = true"
                />
                <Button
                  v-if="patInfo.survey.status == 'Sem relatório'"
                  v-tooltip="'Inserir Relatório'"
                  label="Inserir Relatório"
                  icon="pi pi-upload"
                  class="p-button-success p-mr-1 p-mt-1"
                  @click="addSurveyReport = true"
                />
                <Button
                  v-if="
                    patInfo.survey.status == 'Por marcar' ||
                    patInfo.survey.status == 'Sem Orçamento' ||
                    patInfo.survey.status == 'Por realizar' ||
                    patInfo.survey.status == 'Tentativa Marcação' ||
                    patInfo.survey.status == 'Por Marcar (Contactar Gestor)' ||
                    patInfo.survey.status == 'Marcado'
                  "
                  v-tooltip="'Marcar Survey'"
                  label="Marcar/Remarcar Survey"
                  icon="pi pi-calendar"
                  class="p-button-primary p-mr-1 p-mt-1"
                  @click="setSurveyMarked = true"
                />
                <Button
                  v-if="patInfo.survey.status == 'Por realizar'"
                  v-tooltip="'Marcar como Realizado'"
                  label="Marcar como Realizado"
                  icon="pi pi-check"
                  class="p-button-primary p-mr-1 p-mt-1"
                  @click="setRealized = true"
                />
                <Button
                  v-if="
                    patInfo.survey.status == 'Tentativa Marcação' ||
                    patInfo.survey.status == 'Por marcar'
                  "
                  v-tooltip="'Adicionar Tentativa de Marcação'"
                  label="Tentativa de Marcação"
                  icon="pi pi-phone"
                  class="p-button-primary p-mr-1 p-mt-1"
                  @click="addAttemptSchedule = true"
                />
                <Button
                  v-if="
                    can('cancelSurveys') &&
                    (patInfo.survey.status == 'Tentativa Marcação' ||
                      patInfo.survey.status == 'Por marcar' ||
                      patInfo.survey.status == 'Por Marcar (Contactar Gestor)')
                  "
                  label="Cancelar Survey"
                  icon="pi pi-ban"
                  class="p-button-danger p-mr-1 p-mt-1"
                  @click="setSurveyCanceled = true"
                />
                <Button
                  v-if="
                    can('removeCancelSurveys') &&
                    patInfo.survey.status == 'Cancelado'
                  "
                  label="Remover Survey de Cancelado"
                  icon="pi pi-undo"
                  class="p-button-success p-mr-1 p-mt-1"
                  @click="removeSurveyCanceled = true"
                />
                <Button
                  v-if="
                    patInfo.survey.userToDoId != undefined &&
                    (loggedUser.isEsa ||
                      loggedUser.id == patInfo.survey.sellerId ||
                      loggedUser.id == patInfo.survey.userToDoId) &&
                    patInfo.survey.status == 'Sem Orçamento' &&
                    !patInfo.survey.budgeted
                  "
                  label="Marcar Como Orçamentado"
                  icon="pi pi-euro"
                  class="p-button-success p-mr-1 p-mt-1"
                  @click="changeBudgetedStatus"
                />
              </div>
              <div class="p-col-11">
                <label><b>Estado: </b></label>
                <Textarea
                  :value="patInfo.survey.status"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div
                class="p-col-11"
                v-if="patInfo.survey.status == 'Tentativa Marcação'"
              >
                <label><b>Tentativas de Marcação: </b></label>
                <Textarea
                  :value="numberAttemptSchedule()"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div
                class="p-col-11"
                v-if="patInfo.survey.lastComment != undefined"
              >
                <label><b>Comentário: </b></label>
                <pre style="text-wrap: wrap">
                  {{ patInfo.survey.lastComment }}
                </pre>
              </div>
              <div class="p-col-11">
                <label><b>Data Survey: </b></label>
                <Textarea
                  :value="
                    patInfo.survey.date == '1900-01-01'
                      ? ''
                      : patInfo.survey.date
                  "
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="
                  patInfo.survey.status == 'Por concluir' ||
                  patInfo.survey.status == 'Por marcar' ||
                  patInfo.survey.status == 'Marcado'
                "
              >
                <Button
                  v-tooltip="'Alterar data do survey'"
                  icon="pi pi-calendar"
                  class="p-button-rounded p-button-help p-button-outlined p-mr-2"
                  @click="changeSurveyDate = true"
                />
              </div>
              <div class="p-col-11">
                <label><b>Técnico: </b></label>
                <Textarea
                  :value="patInfo.survey.technician"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="
                  loggedUser.isEsa ||
                  loggedUser.isAdmin ||
                  loggedUser.id == patInfo.survey.technicianUserId
                "
              >
                <Button
                  v-tooltip="'Trocar Técnico Responsável pelo Survey'"
                  icon="pi pi-users"
                  class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
                  @click="changeSurveyResponsible = true"
                />
              </div>
              <div class="p-col-11">
                <label><b>Vendedor: </b></label>
                <Textarea
                  :value="patInfo.survey.seller"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="
                  loggedUser.isEsa ||
                  loggedUser.isAdmin ||
                  loggedUser.id == patInfo.survey.sellerId
                "
              >
                <Button
                  v-tooltip.left="'Trocar Vendedor do Survey'"
                  icon="pi pi-briefcase"
                  class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
                  @click="changeSurveySeller = true"
                />
              </div>
              <div class="p-col-11">
                <label><b>Orçamentado: </b></label>
                <Textarea
                  :value="patInfo.survey.budgeted ? 'Sim' : 'Não'"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="
                  patInfo.survey.userToDoId != undefined &&
                  (loggedUser.isEsa ||
                    loggedUser.id == patInfo.survey.sellerId ||
                    loggedUser.id == patInfo.survey.userToDoId)
                "
              >
                <Button
                  v-if="!patInfo.survey.budgeted"
                  v-tooltip="'Marcar como Orçamentado'"
                  icon="pi pi-euro"
                  class="p-button-rounded p-button-success p-button-outlined p-mr-2"
                  @click="changeBudgetedStatus"
                />
                <Button
                  v-else-if="patInfo.survey.budgeted"
                  v-tooltip="'Marcar como não Orçamentado'"
                  icon="pi pi-euro"
                  class="p-button-rounded p-button-danger p-button-outlined p-mr-2"
                  @click="changeBudgetedStatus"
                />
              </div>
              <div class="p-col-11">
                <label><b>Responsável pelo Orçamento: </b></label>
                <Textarea
                  :value="patInfo.survey.userToDo"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="
                  (loggedUser.isEsa || loggedUser.isAdmin) &&
                  !patInfo.survey.budgeted
                "
              >
                <Button
                  v-tooltip="'Trocar Técnico Rsponsável pelo Orçamento'"
                  icon="pi pi-users"
                  class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
                  @click="changeBudgetedResponsible = true"
                />
              </div>
              <div class="p-col-12">
                <Button
                  v-if="patInfo.solution != ''"
                  label="Ver Relatório"
                  icon="pi pi-bars"
                  class="p-button-success"
                  @click="showSurveyReport = true"
                />
                <Button
                  v-if="patInfo.hasFile == true"
                  label="Download Relatório"
                  icon="pi pi-download"
                  class="p-button-success p-ml-2"
                  @click="downloadReport()"
                />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
      <div class="card p-shadow-6">
        <Accordion :activeIndex="activeIndex" :multiple="true">
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-info-circle p-mr-2"></i>PAT
              </div>
            </template>
            <div>
              <label><b>Tipo de Pat: </b></label>
              <Textarea
                :value="patInfo.patType"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label><b>Classificação: </b></label>
              <Textarea
                :value="patInfo.classification"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label><b>Nome: </b></label>
              <Textarea
                :value="patInfo.agname + ' | ' + patInfo.name"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label><b>Resumo: </b></label>
              <Textarea
                :value="patInfo.resume"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label><b>Problema: </b></label>
              <Textarea
                :value="patInfo.problem"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label><b>Solução: </b></label>
              <Textarea
                :value="patInfo.solution"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label><b>Estado: </b></label>
              <Textarea
                :value="patInfo.status"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label><b>Vendedor: </b></label>
              <Textarea
                :value="patInfo.seller"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label><b>Técnico: </b></label>
              <Textarea
                :value="patInfo.technician"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label>
                <b>Dados de Contacto: </b>
              </label>
              <Textarea
                :value="patInfo.contactDetails"
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
            </div>
            <div class="p-mt-3">
              <label>
                <b>Localização: </b>
              </label>
              <Textarea
                :value="
                  patInfo.address +
                  '\n' +
                  patInfo.zipCode +
                  '\n' +
                  patInfo.local
                "
                :autoResize="true"
                disabled
                style="width: 100%"
                rows="1"
              />
              <Button
                label="Ver no mapa"
                class="p-button-raised p-button-text"
                icon="pi pi-map-marker"
                @click="goToAdrress"
              />
            </div>
            <div class="p-grid">
              <div class="p-mt-3 p-md-4" v-if="patInfo.patType == 'Suporte'">
                <label>
                  <b>Dias em Progresso: </b>
                </label>
                <Textarea
                  :value="patInfo.daysInProgress"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div class="p-mt-3 p-md-4" v-if="patInfo.patType == 'Suporte'">
                <label>
                  <b>Horas Estimadas: </b>
                </label>
                <Textarea
                  :value="patInfo.hoursExpected"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div class="p-mt-3 p-md-4" v-if="patInfo.patType == 'Suporte'">
                <label>
                  <b>Horas Utilizadas: </b>
                </label>
                <Textarea
                  :value="patInfo.hoursUsed"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div class="p-md-6" v-if="patInfo.patType == 'Suporte'">
                <label>
                  <b>SLA Contratado: </b>
                </label>
                <Textarea
                  :value="patInfo.slaHours"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div class="p-md-6" v-if="patInfo.patType == 'Suporte'">
                <label>
                  <b>SLA Horas: </b>
                </label>
                <Textarea
                  :value="patInfo.slaHired"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div class="p-md-12">
                <label>
                  <b>SLA Razão: </b>
                </label>
                <Textarea
                  :value="patInfo.slaDelayReason"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div class="p-md-4">
                <label>
                  <b>Data do 1º Contato: </b>
                </label>
                <Textarea
                  :value="patInfo.dateFirstContact"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div class="p-md-4">
                <label>
                  <b>Data do Pat: </b>
                </label>
                <Textarea
                  :value="patInfo.date + ' ' + patInfo.hour"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
              <div class="p-md-4">
                <label>
                  <b>Data de Criação: </b>
                </label>
                <Textarea
                  :value="patInfo.createAt"
                  :autoResize="true"
                  disabled
                  style="width: 100%"
                  rows="1"
                />
              </div>
            </div>
            <divider />
          </AccordionTab>
          <AccordionTab
            class="accordion-custom"
            v-if="Object.keys(patInfo).length > 0"
          >
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-list p-mr-2"></i>Intervenções
              </div>
            </template>
            <div
              v-for="task in patInfo.tasks"
              :key="task.id"
              class="p-col-12 p-grid"
            >
              <div class="p-col-12 p-md-6">
                <div>
                  <label><b>Tipo</b></label>
                  <InputText
                    type="text"
                    v-model="task.type"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-mt-3">
                  <label><b>Técnico</b></label>
                  <InputText
                    type="text"
                    v-model="task.technician"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-mt-3">
                  <label><b>Dia</b></label>
                  <InputText
                    type="text"
                    :value="getDate(task.start)"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-mt-3">
                  <label>
                    <b
                      >{{
                        task.service_start == ""
                          ? "Início"
                          : "Início da Deslocação"
                      }}:
                    </b></label
                  >
                  <InputText
                    type="text"
                    :value="getTime(task.start)"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-mt-3" v-if="task.service_start != ''">
                  <label>
                    <b>Início do Serviço</b>
                  </label>
                  <InputText
                    type="text"
                    :value="task.service_start"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-mt-3" v-if="task.service_end != ''">
                  <label>
                    <b>Fim do Serviço: </b>
                  </label>
                  <InputText
                    type="text"
                    :value="task.service_end"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-mt-3">
                  <label>
                    <b>
                      {{
                        task.service_end == "" ? "Fim" : "Fim da Deslocação"
                      }}:
                    </b>
                  </label>
                  <InputText
                    type="text"
                    :value="getTime(task.end)"
                    style="width: 100%"
                    disabled
                  />
                </div>
              </div>
              <div class="p-col-12 p-md-6">
                <div v-if="task.note != ''">
                  <label><b>Nota</b></label>
                  <Textarea
                    :value="task.note"
                    :autoResize="true"
                    disabled
                    rows="1"
                    style="width: 100%"
                  />
                </div>
                <div class="p-mt-3">
                  <label><b>Trabalho Efetuado</b></label>
                  <Textarea
                    :value="task.resume"
                    :autoResize="true"
                    disabled
                    style="width: 100%"
                    rows="1"
                  />
                </div>
                <div v-if="task.description != ''" class="p-mt-3">
                  <label><b>Pendente Próxima</b></label>
                  <Textarea
                    :value="task.description"
                    :autoResize="true"
                    rows="1"
                    disabled
                    style="width: 100%"
                  />
                </div>
              </div>
              <div
                class="p-field p-col-12 p-md-12 p-text-right"
                style="color: #91969a"
              >
                <small>
                  Criado por {{ task.created_by }} {{ task.created_at }} <br />
                  Última alteração por {{ task.updated_by }}
                  {{ task.updated_at }}
                </small>
              </div>
              <divider />
            </div>
          </AccordionTab>
          <AccordionTab
            class="accordion-custom"
            v-if="patInfo.patComments.length > 0"
          >
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-comments p-mr-2"></i>Comentários do Pat
              </div>
            </template>
            <PrimeVueTimeline
              :value="patInfo.patComments"
              align="left"
              class="customized-timeline"
            >
              <template #marker>
                <span class="custom-marker p-shadow-4 p-mt-3">
                  <i :class="'pi pi-comment'"></i>
                </span>
              </template>
              <template #content="slotProps">
                <Card class="p-shadow-4 p-mt-2">
                  <template #title>
                    {{ slotProps.item.created_by }}
                  </template>
                  <template #subtitle>
                    {{ slotProps.item.created_at }}
                  </template>
                  <template #content>
                    {{ slotProps.item.comment }}
                  </template>
                </Card>
              </template>
            </PrimeVueTimeline>
            <Button
              label="Adicionar Comentários"
              icon="pi pi-comment"
              class="p-button-sm p-button-success p-mt-3"
              @click="
                $router.push(
                  `/project-comments-view/${patInfo.installation.id}`
                )
              "
            />
          </AccordionTab>
        </Accordion>
      </div>
    </div>
    <div
      v-bind:class="[
        { 'p-md-6': compact == false || compact == undefined },
        { 'p-md-12': compact == true },
        'p-col-12',
      ]"
      v-if="patInfo.installation.id"
    >
      <div class="card p-shadow-6">
        <Accordion :activeIndex="[]" :multiple="true">
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-sliders-h p-mr-2"></i>Instalação
              </div>
            </template>
            <Button
              label="Ver Instalação"
              icon="pi pi-eye"
              class="p-button-sm p-button-success p-mb-2"
              @click="
                $router.push(`/installation-details/${patInfo.installation.id}`)
              "
            />
            <div>
              <label><b>Id: </b></label>
              <InputText
                type="text"
                :value="patInfo.installation.id"
                style="width: 100%"
                disabled
              />
            </div>
            <div class="p-mt-3">
              <label><b>Nome: </b></label>
              <InputText
                type="text"
                :value="patInfo.installation.name"
                style="width: 100%"
                disabled
              />
            </div>
            <div class="p-mt-3">
              <label><b>Descrição: </b></label>
              <InputText
                type="text"
                :value="patInfo.installation.description"
                style="width: 100%"
                disabled
              />
            </div>
            <div class="p-mt-3">
              <label><b>Concluido: </b></label>
              <InputText
                type="text"
                :value="patInfo.installation.concluded ? 'Sim' : 'Não'"
                style="width: 100%"
                disabled
              />
            </div>
            <div class="p-mt-3">
              <label><b>Data da conclusão: </b></label>
              <InputText
                type="text"
                :value="patInfo.installation.concluision_date"
                style="width: 100%"
                disabled
              />
            </div>
            <div class="p-mt-3">
              <label><b>Vendedor: </b></label>
              <InputText
                type="text"
                :value="patInfo.installation.seller"
                style="width: 100%"
                disabled
              />
            </div>
            <div class="p-mt-3">
              <label><b>Gestor: </b></label>
              <InputText
                type="text"
                :value="patInfo.installation.manager"
                style="width: 100%"
                disabled
              />
            </div>
            <div class="p-mt-3">
              <label><b>Técnico: </b></label>
              <InputText
                type="text"
                :value="patInfo.installation.technician"
                style="width: 100%"
                disabled
              />
            </div>
            <divider />
            <Button
              v-if="patInfo.installation.has_installation_sheet"
              label="Ver Folha de Instalação"
              icon="pi pi-sliders-h"
              class="p-button-sm p-button-success p-ml-1"
              @click="
                $router.push(
                  `/task-installation-sheet/${patInfo.installation.id}/1`
                )
              "
            />
            <Button
              v-if="patInfo.installation.satisfactionForm.length > 0"
              label="Inquérito de Satisfação"
              icon="pi pi-check-circle"
              class="p-button-sm p-button-success p-ml-1"
              @click="
                $router.push(
                  `/satisfaction-form-installations-quiz/${patInfo.installation.id}`
                )
              "
            />
            <div v-if="patInfo.externalManager.name != undefined">
              <divider />
              <div>
                <h5>Gestor Externo (NOS):</h5>
                <div>
                  <label><b>Nome: </b></label>
                  <InputText
                    type="text"
                    :value="patInfo.externalManager.name"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-mt-3">
                  <label><b>Função: </b></label>
                  <InputText
                    type="text"
                    :value="patInfo.externalManager.type"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-mt-3">
                  <label><b>Telefone: </b></label>
                  <InputText
                    type="text"
                    :value="patInfo.externalManager.telephone"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-mt-3">
                  <label><b>Email: </b></label>
                  <InputText
                    type="text"
                    :value="patInfo.externalManager.email"
                    style="width: 100%"
                    disabled
                  />
                </div>
              </div>
            </div>
          </AccordionTab>
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-comments p-mr-2"></i>Comentários
              </div>
            </template>
            <PrimeVueTimeline
              :value="commentsList"
              align="left"
              class="customized-timeline"
            >
              <template #marker>
                <span class="custom-marker p-shadow-4 p-mt-3">
                  <i :class="'pi pi-comment'"></i>
                </span>
              </template>
              <template #content="slotProps">
                <Card class="p-shadow-4 p-mt-2">
                  <template #title>
                    {{ slotProps.item.created_by }}
                  </template>
                  <template #subtitle>
                    {{ slotProps.item.created_at }}
                  </template>
                  <template #content>
                    {{ slotProps.item.comment }}
                  </template>
                </Card>
              </template>
            </PrimeVueTimeline>
            <Button
              label="Adicionar Comentários"
              icon="pi pi-comment"
              class="p-button-sm p-button-success p-mt-3"
              @click="
                $router.push(
                  `/project-comments-view/${patInfo.installation.id}`
                )
              "
            />
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script>
import patService from "../services/pats.service";
import installationservice from "../services/installations.service";
import filesService from "../services/files.service";
import { getOnlyDateUserFormat, getOnlyTime } from "../helpers/helpers";
import PrimeVueTimeline from "primevue/timeline";
import SurveyChangeDate from "../components/SurveyChangeDate";
import SurveySetBudget from "../components/SurveySetBudget";
import SurveyChangeBudgetResponsible from "../components/SurveyChangeBudgetResponsible";
import SurveySetPendingStatus from "../components/SurveySetPendingStatus";
import SurveyRemovePendingStatus from "../components/SurveyRemovePendingStatus";
import SurveySetClosedStatus from "../components/SurveySetClosedStatus";
import SurveySetConcluedStatus from "../components/SurveySetConcluedStatus";
import SurveyAddReport from "../components/SurveyAddReport";
import SurveyChangeSurveyResponsible from "../components/SurveyChangeSurveyResponsible";
import SurveyChangeSurveySeller from "../components/SurveyChangeSurveySeller";
import SurveyMarkeIntervention from "../components/SurveyMarkIntervention.vue";
import SurveySetRealizedStatus from "../components/SurveySetConcluedStatus.vue";
import SurveyAddAttempt from "../components/SurveyAddAttempt.vue";
import SurveySetCancelled from "../components/SurveySetCanceled.vue";
import SurveyRemoveCanceled from "../components/SurveyRemoveCanceled.vue";
import Proposal from "./ProposalDetail.vue";
export default {
  name: "PatDetails",
  props: ["patId", "compact"],
  components: {
    PrimeVueTimeline,
    SurveyChangeDate,
    SurveySetBudget,
    SurveyChangeBudgetResponsible,
    SurveySetPendingStatus,
    SurveyRemovePendingStatus,
    SurveySetClosedStatus,
    SurveySetConcluedStatus,
    SurveyAddReport,
    SurveyChangeSurveyResponsible,
    SurveyChangeSurveySeller,
    SurveyMarkeIntervention,
    SurveySetRealizedStatus,
    SurveyAddAttempt,
    SurveySetCancelled,
    SurveyRemoveCanceled,
    Proposal,
  },
  data() {
    return {
      loading: true,
      patInfo: {},
      commentsList: [],
      changeSurveyDate: false,
      setSurveyStatusPending: false,
      removeSurveyStatusPending: false,
      changeBudgetedResponsible: false,
      setSurveyClosed: false,
      setSurveyConclude: false,
      addSurveyReport: false,
      changeSurveyResponsible: false,
      changeSurveySeller: false,
      setSurveyMarked: false,
      setSurveyCanceled: false,
      removeSurveyCanceled: false,
      setRealized: false,
      addAttemptSchedule: false,
      setSurveyBudget: {
        show: false,
        patId: null,
        budgeted: null,
        patType: "Survey",
        patName: null,
      },
      showSurveyReport: false,
      activeIndex: [0, 1],
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    this.loading = true;
    await this.getInfo();
    if (this.patInfo.installation.id) {
      await this.getComments(this.patInfo.installation.id);
    }
    this.activeIndex = [0];
    this.loading = false;
  },
  methods: {
    getInfo() {
      let patId = this.patId ? this.patId : this.$route.params.id;
      return patService.getPatInfo(patId).then((response) => {
        return (this.patInfo = response);
      });
    },
    getComments(installationId) {
      this.loading = true;
      installationservice
        .getInstallationComments(installationId)
        .then((response) => {
          this.commentsList = response.comments;
        });
    },
    getDate(dateTime) {
      return getOnlyDateUserFormat(new Date(dateTime));
    },
    getTime(dateTime) {
      return getOnlyTime(new Date(dateTime));
    },
    goToAdrress() {
      window.open(
        `http://maps.google.com/?q=${this.patInfo.localization.address}, ${this.patInfo.localization.zip_code}, ${this.patInfo.localization.local}`,
        "_blank"
      );
    },
    surveyChangeDateCancel() {
      this.changeSurveyDate = false;
    },
    surveyChangeDateSubmit(response) {
      if (response.status == "ok") {
        this.patInfo.survey.date = response.currentDate;
        this.patInfo.date = response.currentDate;
      }
      this.changeSurveyDate = false;
    },
    changeBudgetedStatus() {
      this.setSurveyBudget.show = true;
      this.setSurveyBudget.patId = this.patInfo.pat;
      this.setSurveyBudget.budgeted = this.patInfo.survey.budgeted;
      this.setSurveyBudget.patType = this.patInfo.patType;
      this.setSurveyBudget.patName =
        this.patInfo.agname + " | " + this.patInfo.name;
    },
    changeBudgetedStatusCancel() {
      return (this.setSurveyBudget = {
        show: false,
        patId: null,
        budgeted: null,
        patType: "Survey",
        patName: null,
      });
    },
    changedBudgetedStatus(response) {
      if (response.status == "ok") {
        this.patInfo.survey.budgeted = response.currentStatus;
      }
      this.changeBudgetedStatusCancel();
    },
    changeSurveyBudgetResponsibleCancel() {
      this.changeBudgetedResponsible = false;
    },
    changedSurveyBudgetResponsible(response) {
      if (response.status == "ok") {
        this.patInfo.survey.userToDoId = response.currentResponsible.id;
        this.patInfo.survey.userToDo = response.currentResponsible.name;
      }
      this.changeBudgetedResponsible = false;
    },
    setSurveyPendingCancel() {
      this.setSurveyStatusPending = false;
    },
    setSurveyPending(response) {
      if (response.status == "ok") {
        this.patInfo.status = response.message.patStatus;
        this.patInfo.survey.status = response.message.surveyStatus;
        this.patInfo.survey.lastComment = response.message.surveyLastComment;
      }
      this.setSurveyStatusPending = false;
    },
    removePendingStatusCancel() {
      this.removeSurveyStatusPending = false;
    },
    removePendingStatus(response) {
      if (response.status == "ok") {
        this.patInfo.status = response.currentStatus.patStatus;
        this.patInfo.survey.status = response.currentStatus.surveyStatus;
        this.patInfo.survey.lastComment =
          response.currentStatus.surveyLastComment;
      }
      this.removeSurveyStatusPending = false;
    },
    setClosedCancel() {
      this.setSurveyClosed = false;
    },
    setClosedStatus(response) {
      if (response.status == "ok") {
        this.patInfo.status = response.currentStatus.patStatus;
        this.patInfo.survey.status = response.currentStatus.surveyStatus;
      }
      this.setSurveyClosed = false;
    },
    setMarkedCancel() {
      this.setSurveyMarked = false;
    },
    setMarkedOk(response) {
      if (response.status == "ok") {
        this.patInfo.status = response.currentStatus.patStatus;
        this.patInfo.survey.status = response.currentStatus.surveyStatus;
      }
      this.setMarkedCancel = false;
    },
    setConcluedCancel() {
      this.setSurveyConclude = false;
    },
    setConcluedStatus(response) {
      if (response.status == "ok") {
        this.patInfo.status = response.currentStatus.patStatus;
        this.patInfo.survey.status = response.currentStatus.surveyStatus;
      }
      this.setSurveyConclude = false;
    },
    addReportCancel() {
      this.addSurveyReport = false;
    },
    addedReport(response) {
      if (response.status == "ok") {
        this.patInfo.survey.status = response.currentStatus.surveyStatus;
      }
      this.addSurveyReport = false;
    },
    setRealizedCancel() {
      this.setRealized = false;
    },
    setRealizedOk(response) {
      if (response.status == "ok") {
        this.patInfo.survey.status = response.currentStatus.surveyStatus;
      }
      this.setRealizedCancel();
    },
    addAttemptScheduleCancel() {
      this.addAttemptSchedule = false;
    },
    addAttemptScheduleOk(response) {
      if (response.status == "ok") {
        this.patInfo.survey.status = response.currenStatus;
        this.patInfo.survey.attemptSchedule = response.attemptSchedule;
        this.patInfo.survey.attemptSchedule = response.attemptSchedule;
        this.patInfo.survey.lastComment = response.lastComment;
      }
      return this.addAttemptScheduleCancel();
    },
    setCanceledCancel() {
      this.setSurveyCanceled = false;
    },
    setCanceledOk(response) {
      if (response.status == "ok") {
        this.patInfo.survey.status = response.currenStatus;
      }
      return this.setCanceledCancel();
    },
    removeCanceledOk(response) {
      if (response.status == "ok") {
        this.patInfo.status = response.currentStatus.patStatus;
        this.patInfo.survey.status = response.currentStatus.surveyStatus;
      }
      return (this.removeSurveyCanceled = false);
    },
    changeSurveyResponsibleCancel() {
      this.changeSurveyResponsible = false;
    },
    changedSurveyResponsible(response) {
      if (response.status == "ok") {
        this.patInfo.survey.technicianUserId = response.currentResponsible.id;
        this.patInfo.survey.technician = response.currentResponsible.name;
        this.patInfo.technician = response.currentResponsible.name;
      }
      this.changeSurveyResponsible = false;
    },
    changeSurveySellerCancel() {
      this.changeSurveySeller = false;
    },
    changedSurveySeller(response) {
      if (response.status == "ok") {
        this.patInfo.survey.sellerId = response.currentSeller.id;
        this.patInfo.survey.seller = response.currentSeller.name;
      }
      this.changeSurveySeller = false;
    },
    downloadReport() {
      let data = {
        origin_reference: this.patInfo.reference,
      };
      filesService.getFileFromOriginReference(data).then((response) => {
        if (!response || response.length == 0) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao fazero download",
            life: 3000,
          });
        }
        var downloadElement = document.createElement("a");

        if (response[0].type !== undefined && response[0].type == "pdf") {
          downloadElement.href = `data:application/${response[0].type};base64,${response[0].src}`;
        } else if (response[0].type !== undefined) {
          downloadElement.href = `data:image/${response[0].type};base64,${response[0].src}`;
        }
        let fileName = this.patInfo.name
          .replace(/\w+/g, function (txt) {
            // uppercase first letter and add rest unchanged
            return txt.charAt(0).toUpperCase() + txt.substr(1);
          })
          .replace(/\s/g, "");
        downloadElement.download = `${this.patInfo.pat}-${fileName}.${response[0].type}`;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        downloadElement.remove();
      });
    },
    numberAttemptSchedule() {
      return this.patInfo.survey.attemptSchedule.split("_")[0];
    },
  },
};
</script>
<style>
.custom-marker {
  display: -webkit-;
  display: -ms-flexbox;
  display: flex;
  width: 2rem;
  height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
  background-color: #ff4910;
}
.p-timeline-event-opposite {
  display: none !important;
}
.p-dialog .p-dialog-header-icons {
  display: none !important;
}

.p-accordion-header-link {
  background-color: #474a54 !important;
  color: #fff !important;
}
</style>
